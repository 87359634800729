.talent-page header {
  background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../images/homeimg.jpg);
}

.head {
  text-align: end;
}

.detail {
  text-align: start;
}