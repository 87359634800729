.home-page header {
    background-image: linear-gradient(rgba(92, 92, 95, 0.645), rgba(9, 9, 137, 0.645)), url(../../../public/img/banner-acm.png);
    background-size: cover;
    background-repeat: no-repeat;
}

header {
    background-size: cover;
    background-position: center;
}

.blog-section {
    background-image: linear-gradient(rgba(9, 9, 137, 0.645), rgba(9, 9, 137, 0.645)), url(../../../public/img/library-acm.jpg);
    background-size: cover;
    background-position: center;
}

.img-card {

    margin: auto;
}

/* .c2 {
    height: 100% !important;
} */